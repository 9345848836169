import { KeyValue } from '@mews/enums';

import addStyleProperties from './addStyleProperties';
import {
    DISABLED_SCROLL_STYLES,
    IFRAME_BODY_STYLES,
    IFRAME_HTML_STYLES,
    IFRAME_NAME,
    IFRAME_SRCDOC,
    IFRAME_STYLES,
    IFRAME_TRANSITION_LENGTH,
    IFRAME_ZINDEX,
    ONE_TICK,
} from './bootstrapConstants';

export default function createAppContainer(contextWindow, openElements, { isStandalone = false } = {}) {
    const iframe = contextWindow.document.createElement('iframe');
    iframe.className = IFRAME_NAME;
    iframe.name = IFRAME_NAME + Date.now();
    addStyleProperties(iframe, IFRAME_STYLES);

    const iframeToggler = createIframeToggler(contextWindow, iframe);

    let loaded = false;
    let wasEarlyOpened = false;
    let isAppLoaded = false;
    let appInstance;

    iframe.onload = () => {
        loaded = true;

        /**
         * Some browsers execute onload after manipulation with contentDocument.
         * Without this check, those browsers would be stuck in infinite loop of
         * contentDocument manipulations (contentDocument.open, write, close) and onload executions.
         * We know this is a case for Internet Explorer 11.
         */
        if (iframe.contentDocument.doctype) {
            return;
        }

        // add doctype to the iframe
        const iframeContentDocument = iframe.contentDocument;
        iframeContentDocument.open();
        // IFRAME_SRCDOC contains only constants
        // eslint-disable-next-line no-unsanitized/method
        iframeContentDocument.write(IFRAME_SRCDOC);
        iframeContentDocument.close();

        // css reset for iframes html and body tag
        addStyleProperties(iframe.contentWindow.document.body, IFRAME_BODY_STYLES);
        addStyleProperties(iframe.contentDocument.getElementsByTagName('html')[0], IFRAME_HTML_STYLES);

        // bind closing of iframe to escape key
        if (!isStandalone) {
            iframe.contentWindow.addEventListener('keydown', (e) => {
                if (e.key === KeyValue.Escape) {
                    iframeToggler.closeIframe();
                }
            });
        }

        const opener = getOpener(contextWindow, openElements, () => {
            open();
            if (appInstance) {
                appInstance.open();
            }
        });
        contextWindow.document.addEventListener('click', opener);
    };

    const api = {
        getElement,
        getDocument,
        onLoad,
        open,
        close: iframeToggler.closeIframe,
        loadApp,
        getIframeContentWindow,
        loadReactDevtools,
    };

    return api;

    function getIframeContentWindow() {
        return iframe.contentWindow;
    }

    function getElement() {
        return iframe;
    }

    function getDocument() {
        return iframe.contentDocument;
    }

    function onLoad(callback) {
        if (loaded) {
            callback(api);
        } else {
            const { onload } = iframe;
            iframe.onload = () => {
                onload();
                callback(api);
            };
        }
    }

    function open() {
        if (!isAppLoaded) {
            wasEarlyOpened = true;
            iframe.contentDocument.body.style.cursor = 'wait';
        }

        iframeToggler.openIframe();
    }

    function loadReactDevtools() {
        iframe.contentWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__ = contextWindow.__REACT_DEVTOOLS_GLOBAL_HOOK__; // eslint-disable-line no-underscore-dangle
    }

    function loadApp(src, appOptions, initialState, callback) {
        // insert app script into iframe
        const script = contextWindow.document.createElement('script');
        script.crossorigin = 'anonymous';
        script.src = src;
        script.onload = () => {
            script.onload = null;
            isAppLoaded = true;
            appInstance = iframe.contentWindow.Mews.runDistributorApp(appOptions, initialState);

            if (wasEarlyOpened) {
                iframe.contentDocument.body.style.cursor = 'auto';
                appInstance.open();
            }

            callback(appInstance);
        };
        iframe.contentDocument.head.appendChild(script);
    }
}

function createIframeToggler(contextWindow, iframe) {
    const scrollToggler = createScrollToggler(contextWindow);
    let isOpened = false;

    return {
        openIframe() {
            if (!isOpened) {
                isOpened = true;

                addStyleProperties(iframe, { opacity: 0.01, zIndex: IFRAME_ZINDEX });
                contextWindow.setTimeout(
                    () => addStyleProperties(iframe, { opacity: 1, transform: 'translateY(0)' }),
                    ONE_TICK
                );

                scrollToggler.disableScroll();
            }
        },

        closeIframe() {
            if (isOpened) {
                isOpened = false;

                addStyleProperties(iframe, { opacity: 0, transform: 'translateY(-20%)' });
                contextWindow.setTimeout(() => addStyleProperties(iframe, { zIndex: -1 }), IFRAME_TRANSITION_LENGTH);

                scrollToggler.enableScroll();
            }
        },

        isOpened() {
            return isOpened;
        },
    };
}

function createScrollToggler(contextWindow) {
    const getHtml = () => contextWindow.document.getElementsByTagName('html')[0];
    const getBody = () => contextWindow.document.body;

    const changedStyleKeys = Object.keys(DISABLED_SCROLL_STYLES);

    let scrollPosition;
    let originalHtmlStyle;
    let originalBodyStyle;

    let isDisabled = false;

    return {
        disableScroll() {
            if (!isDisabled) {
                const html = getHtml();
                const body = getBody();

                scrollPosition = contextWindow.pageYOffset;
                if (html) {
                    originalHtmlStyle = pick(html.style, changedStyleKeys);
                    addStyleProperties(html, DISABLED_SCROLL_STYLES);
                }
                if (body) {
                    originalBodyStyle = pick(body.style, changedStyleKeys);
                    addStyleProperties(body, DISABLED_SCROLL_STYLES);
                }

                isDisabled = true;
            }
        },

        enableScroll() {
            if (isDisabled) {
                const html = getHtml();
                const body = getBody();

                if (html) {
                    addStyleProperties(html, originalHtmlStyle);
                }
                if (body) {
                    addStyleProperties(body, originalBodyStyle);
                }
                contextWindow.scrollTo(0, scrollPosition);

                isDisabled = false;
            }
        },

        isDisabled() {
            return isDisabled;
        },
    };
}

const getOpener = (contextWindow, querySelector, callback) => (e) => {
    const els = Array.prototype.slice.call(contextWindow.document.querySelectorAll(querySelector));
    let found = false;
    els.forEach((el) => {
        let node = e.target;
        while (node !== null) {
            if (node === el) {
                found = true;
                break;
            }
            node = node.parentNode;
        }
    });
    if (found) {
        e.preventDefault();
        callback();
    }
};

function pick(obj, keys) {
    const values = {};

    keys.forEach((k) => {
        if (typeof obj[k] !== 'undefined') {
            values[k] = obj[k];
        }
    });

    return values;
}
