import 'es6-promise/auto';
import 'core-js/features/object/assign';
import 'core-js/features/array/map';
import { parse as parseQueryString } from 'query-string';

import { DEFAULT_CURRENCY_CODE } from '../defaultCurrency';
import { DEFAULT_LANGUAGE_CODE } from '../defaultLanguage';

import addStyleProperties from './addStyleProperties';
import {
    APP_CONTAINER_ID,
    CONFIGURATION_FILENAME,
    LOCALIZATION_FILENAME,
    ONE_TICK,
    PLATFORM_PRIMARY_API_URL,
} from './bootstrapConstants';
import createAppContainer from './createAppContainer';
import loadJson from './loadJson';

// eslint-disable-next-line no-undef
const version = __VERSION__;

global.Mews = global.Mews || {};

global.Mews.D = function D(configurationIds, dataBaseUrl) {
    const initDistributor = () =>
        global.Mews.Distributor({ configurationIds, openElements: '.distributor' }, null, { dataBaseUrl });

    if (global.readyState === 'loading') {
        global.addEventListener('DOMContentLoaded', initDistributor);
    } else {
        initDistributor();
    }
};

global.Mews.Distributor = function Distributor(
    window,
    customOptions,
    loadCallback = () => {},

    // hidden override options
    {
        dataBaseUrl = PLATFORM_PRIMARY_API_URL,
        appUrl = null,
        configuration = null,
        localization = null,
        assetBaseUrl = null,
    } = {}
) {
    const {
        // app
        isStandalone,

        // settings
        hotelIds,
        configurationIds,
        adultCount,
        childCount,
        rooms,
        gtmContainerId,
        introVideoSrc,

        // features
        hideSpecialRequests,
        showRateCompare,
        competitors,

        // theme
        theme: { primaryColor } = {},

        // initialState options
        startDate,
        endDate,
        language,
        currency,

        // bootstrap options
        openElements,

        // backwards compatibility options
        hotelId,
    } = customOptions;

    const appContainer = createAppContainer(window, openElements, { isStandalone });
    appContainer.onLoad(loadApp);
    window.setTimeout(() => window.document.body.appendChild(appContainer.getElement()), ONE_TICK);

    function loadApp() {
        const toLowerCase = (str) => (typeof str !== 'undefined' ? String.prototype.toLowerCase.call(str) : null);

        const configurationUrl = `${dataBaseUrl}/${CONFIGURATION_FILENAME}`;

        Promise.resolve()
            .then(() => configuration || loadJson(configurationUrl))
            .then((serverConfiguration) => {
                const { platformApiUrl } = serverConfiguration;
                const localizationUrl = `${platformApiUrl}/${LOCALIZATION_FILENAME}`;

                return Promise.all([
                    Promise.resolve().then(() => serverConfiguration),
                    Promise.resolve().then(() => localization || loadJson(localizationUrl)),
                ]);
            })
            .then(([serverConfiguration, globalization]) => {
                const queryString = parseQueryString(window.location.search);

                const shouldOpen =
                    typeof queryString.mewsDistributorOpened !== 'undefined' ||
                    (hotelId && queryString.mewsEnterpriseId === hotelId);
                const initialState = {
                    app: {
                        availabilityBlockIdFromQuery: queryString.mewsAvailabilityBlockId,
                        languageCode: queryString.language || language || DEFAULT_LANGUAGE_CODE,
                        languageCodeFromQuery: queryString.language,
                        currencyCode: currency || DEFAULT_CURRENCY_CODE,
                        currencyCodeFromQuery: queryString.currency,
                        cityIdFromQuery: toLowerCase(queryString.mewsCityId),
                        routeFromQuery: toLowerCase(queryString.mewsRoute),
                        roomFromQuery: toLowerCase(queryString.mewsRoom),
                        hotelFromQuery: toLowerCase(queryString.mewsHotel),
                        startDateFromQuery: toLowerCase(queryString.mewsStart),
                        endDateFromQuery: toLowerCase(queryString.mewsEnd),
                        voucherCodeFromQuery: queryString.mewsVoucherCode,
                        reservationGroupIdFromQuery: queryString.mewsReservationGroupId,
                        paymentCardIdFromQuery: queryString.mewsPaymentCardId,
                        propertyReturnUrlFromQuery: queryString.mewsPropertyReturnUrl,
                        adultCountFromQuery: queryString.mewsAdultCount && parseInt(queryString.mewsAdultCount, 10),
                        childCountFromQuery: queryString.mewsChildCount && parseInt(queryString.mewsChildCount, 10),
                    },
                    resourceOrder: {
                        startDate,
                        endDate,
                    },
                };

                const { ...appConfiguration } = serverConfiguration;
                const finalAssetBaseUrl = assetBaseUrl || serverConfiguration.assetBaseUrl;

                let finalHotelIds = [];
                if (Array.isArray(hotelIds)) {
                    finalHotelIds = hotelIds.map(toLowerCase);
                } else if (hotelId) {
                    finalHotelIds.push(toLowerCase(hotelId));
                }

                const hotels = {};
                finalHotelIds.forEach((id) => {
                    hotels[id] = {
                        settings: {
                            defaultAdultCount: adultCount,
                            defaultChildCount: childCount,
                            roomsFilter: rooms,
                        },
                        features: {
                            showSpecialRequestsField: !hideSpecialRequests,
                            rateCompare: {
                                isEnabled: !!showRateCompare,
                                competitors,
                            },
                        },
                    };
                });

                const appOptions = {
                    contextWindow: window,
                    closeApp: appContainer.close,
                    configuration: {
                        app: {
                            ...appConfiguration,
                            assetBaseUrl: finalAssetBaseUrl,
                            isStandalone: !!isStandalone,
                        },
                        settings: {
                            hotelIds: finalHotelIds,
                            configurationIds,
                            googleTagManagerContainerId: gtmContainerId,
                            introVideoSrc,
                        },
                        hotels,
                        theme: {
                            primaryColor,
                        },
                    },
                    element: `#${APP_CONTAINER_ID}`,
                    globalization,
                    customOptions,
                };

                if (isStandalone || shouldOpen) {
                    appContainer.open();
                }

                appContainer.loadReactDevtools();

                const finalAppUrl = appUrl || getAppUrl(serverConfiguration);

                appContainer.loadApp(finalAppUrl, appOptions, initialState, (distributor) => {
                    // wrap opener
                    const { open } = distributor;
                    distributor.open = () => {
                        // Redirect non-https domains to standalone
                        if (!isSecureContextWithFallback()) {
                            const { platformPrimaryUrl } = serverConfiguration;
                            const ids = configurationIds || finalHotelIds;
                            const configurationQuery = ids.join(';');
                            window.location.replace(`${platformPrimaryUrl}/distributor/${configurationQuery}`);
                        } else {
                            open();
                            appContainer.open();
                        }
                    };

                    // call custom callback
                    if (typeof loadCallback === 'function') {
                        try {
                            loadCallback(distributor);
                        } catch (e) {
                            console.error(e.stack); // eslint-disable-line no-console
                        }
                    }
                });
            })
            .catch(() => {
                createErrorPage({
                    message: 'Loading of the booking engine has failed',
                    hasTryAgain: true,
                });
            });
    }

    function getAppUrl({ assetBaseUrl: assetUrl }) {
        const appFilename = 'distributor-app.js';
        return `${assetUrl}/${version}/${appFilename}`;
    }

    function isSecureContextWithFallback() {
        // Fallback for browsers without isSecureContext support
        if (typeof window.isSecureContext === 'undefined') {
            return isHttps();
        }
        return window.isSecureContext;
    }
    function isHttps() {
        return window.location.protocol === 'https:';
    }
    function createErrorPage({ message, hasTryAgain }) {
        const doc = appContainer.getDocument();
        doc.body.style.cursor = 'default';
        addStyleProperties(appContainer.getElement(), { opacity: 1 });

        const wrapper = doc.createElement('div');
        wrapper.style.textAlign = 'center';

        const title = doc.createElement('h1');
        title.innerText = message;
        wrapper.appendChild(title);

        const close = doc.createElement('a');
        close.href = '';
        close.innerText = 'Close';
        close.addEventListener('click', (e) => {
            e.preventDefault();
            appContainer.close();
        });
        wrapper.appendChild(close);

        if (hasTryAgain) {
            const reload = doc.createElement('a');
            reload.href = '';
            reload.innerText = 'Try again';
            reload.style.marginLeft = '14px';
            reload.addEventListener('click', (e) => {
                e.preventDefault();
                doc.body.innerHTML = '';
                loadApp();
            });
            wrapper.appendChild(reload);
        }

        doc.body.appendChild(wrapper);
    }
}.bind(null, window);
