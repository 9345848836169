export const PLATFORM_PRIMARY_API_URL = 'https://api.mews.com';
export const CONFIGURATION_FILENAME = 'distributor/configuration';
export const LOCALIZATION_FILENAME = 'distributor/globalization';

export const ONE_TICK = 17;
export const IFRAME_ZINDEX = 100000;
export const IFRAME_TRANSITION_LENGTH = 450;
export const IFRAME_NAME = 'mews-distributor';
export const APP_CONTAINER_ID = 'distributor';
export const DEFAULT_TRACKING_CONSENT = true;

export const IFRAME_STYLES = {
    opacity: 0,
    border: 'none',
    width: '100%',
    height: '100%',
    position: 'fixed',
    transform: 'translate3d(0px, 0px, 0px)',
    background: 'white',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    transition: `
        transform ${IFRAME_TRANSITION_LENGTH}ms cubic-bezier(0.23, 1, 0.32, 1),
        opacity ${IFRAME_TRANSITION_LENGTH}ms cubic-bezier(0.23, 1, 0.32, 1)
    `,
};

export const IFRAME_HTML_STYLES = {
    height: '100%',
};

export const IFRAME_BODY_STYLES = {
    margin: 0,
    padding: 0,
    overflowX: 'hidden',
    height: '100%',
};

export const DISABLED_SCROLL_STYLES = {
    overflow: 'hidden',
    margin: 0,
    padding: 0,
};

const LOADER_STYLES = `
    .circle-loader {
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translateX(-20px) translateY(-20px);
    }
`;

const LOADER_SRC = `
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" class="circle-loader">
        <circle cx="20" cy="20" r="15" stroke="#E6E6E6" stroke-width="3.33333" />
        <circle cx="20" cy="20" r="15" stroke-dasharray="23.561944901923447, 94.24777960769379" stroke-width="3.33333" stroke="#4667c3">
            <animateTransform attributeName="transform" attributeType="XML" type="rotate" from="0 20 20" to="360 20 20" dur="1s" repeatCount="indefinite" />
        </circle>
    </svg>
`;
const APP_CONTAINER_SRC = `<div id="${APP_CONTAINER_ID}" style="height: 100%">${LOADER_SRC}</div>`;

export const IFRAME_SRCDOC = `<!DOCTYPE html><html><head><style>${LOADER_STYLES}</style></head><body>${APP_CONTAINER_SRC}</body></html>`;
